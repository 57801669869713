import request from '@/utils/request'

// 应用信息
export function WeGetAppletInfo (query) {
  return request({
    url: '/Applet/WeGetAppletInfo',
    method: 'get',
    params: query
  })
}
// 获取资讯信息列表-居民端
export function WeGetRealInfoPage (query) {
  return request({
    url: 'RealInfo/WeGetRealInfoPage',
    method: 'get',
    params: query
  })
}
// 获取资讯信息-居民端
export function WeGetRealInfo (query) {
  return request({
    url: 'RealInfo/WeGetRealInfo',
    method: 'get',
    params: query
  })
}
// 获取资讯分类列表，选择用
export function GetRealSortList (query) {
  return request({
    url: 'RealInfo/GetRealSortList',
    method: 'get',
    params: query
  })
}
// export function WeGetRegionPage(query) {
//     return request({
//         url: 'Region/WeGetRegionPage',
//         method: 'get',
//         params: query
//     })
// }
// 获取组织机构树[小程序]
export function WeGetOrganTree (data) {
  return request({
    url: 'Organ/WeGetOrganTree',
    method: 'post',
    data
  })
}
export function WeGetAppletOrganList (params) {
  return request({
    url: 'Organ/WeGetAppletOrganList',
    method: 'get',
    params
  })
}