import request from '@/utils/request'
// 景区
export function WeGetScenicSpotPageList (query) {
  return request({
    url: 'ScenicSpot/WeGetScenicSpotPageList',
    method: 'get',
    params: query
  })
}
// 获取农家特产分页列表
export function WeGetSpecialtyPageList (query) {
  return request({
    url: 'Specialty/WeGetSpecialtyPageList',
    method: 'get',
    params: query
  })
}
// 获取景区分类列表
export function WeGetScenicSpotTypePage (query) {
  return request({
    url: 'ScenicSpotType/WeGetScenicSpotTypePage',
    method: 'get',
    params: query
  })
}

// 获取便民商户分页列表
export function WeGetStorePageList (query) {
  return request({
    url: 'Store/WeGetStorePageList',
    method: 'get',
    params: query
  })
}

// 获取乡村景区单条记录
export function WeGetScenicSpotPageBySSId (query) {
  return request({
    url: 'ScenicSpot/WeGetScenicSpotPageBySSId',
    method: 'get',
    params: query
  })
}
// 获取攻略单条记录
export function WeGetStrategyBySTfId (query) {
  return request({
    url: 'Strategy/WeGetStrategyBySTfId',
    method: 'get',
    params: query
  })
}

// 获取景点分页列表
export function WeGetLandScapePage (query) {
  return request({
    url: 'LandScape/WeGetLandScapePage',
    method: 'get',
    params: query
  })
}

// 获取乡村景点单条记录
export function WeGetLandScapeByLSId (query) {
  return request({
    url: 'LandScape/WeGetLandScapeByLSId',
    method: 'get',
    params: query
  })
}

// 获取旅馆分页列表
export function WeGetHotelPage (query) {
  return request({
    url: 'Hotel/WeGetHotelPage',
    method: 'get',
    params: query
  })
}
// 获取旅馆单条记录
export function WeGetHotelByHotelId (query) {
  return request({
    url: 'Hotel/WeGetHotelByHotelId',
    method: 'get',
    params: query
  })
}

// 获取餐馆分页列表
export function WeGetEateryPage (query) {
  return request({
    url: 'Eatery/WeGetEateryPage',
    method: 'get',
    params: query
  })
}
// 获取餐馆单条记录
export function WeGetEateryByEateryId (query) {
  return request({
    url: 'Eatery/WeGetEateryByEateryId',
    method: 'get',
    params: query
  })
}

// 获取农家特产单条记录
export function WeGetSpecialtyPageBySCId (query) {
  return request({
    url: 'Specialty/WeGetSpecialtyPageBySCId',
    method: 'get',
    params: query
  })
}

// 获取户外活动分页列表
export function WeGetOutdoorLifePageList (query) {
  return request({
    url: 'OutdoorLife/WeGetOutdoorLifePageList',
    method: 'get',
    params: query
  })
}